import * as Sentry from '@sentry/sveltekit';

import { envString, isDev } from './utils/envs';

Sentry.init({
	dsn: 'https://53ef0062bc9f94232b31f709f3ce7bb0@sentry.kelp.digital/10',
	debug: isDev(),
	enabled: !isDev(),
	replaysOnErrorSampleRate: 1,
	environment: envString(),
	tracesSampleRate: 1,
	sampleRate: 1,
	replaysSessionSampleRate: 1,
	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		new Sentry.BrowserProfilingIntegration(),
		Sentry.replayIntegration(),
		Sentry.browserTracingIntegration()
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
