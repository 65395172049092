import { equals, includes } from 'ramda';

import { browser } from '$app/environment';

/**
 *
 * @returns
 */
export function isDev() {
	if (browser && includes('localhost', window?.location.hostname)) {
		return true;
	}
	return false;
}
/**
 *
 * @returns
 */
export function isStaging() {
	if (
		browser &&
		(includes('.s.', window?.location.hostname) || includes('.surge.sh', window?.location.hostname))
	) {
		return true;
	}
	return false;
}
export function isProduction() {
	if (browser && equals('kelp.digital', window?.location.hostname)) {
		return true;
	}
	return false;
}

/**
 * Based on the isDev | isStaging | isProduction return env string
 * @returns
 */
export function envString(): string {
	if (isDev()) {
		return 'development';
	} else if (isStaging()) {
		return 'staging';
	} else if (isProduction()) {
		return 'production';
	} else {
		return 'development';
	}
}
